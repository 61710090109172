<template>
  <svg viewBox="0 0 220 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 1000005583">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M207.288 17.2394C207.288 16.6193 208.003 16.2377 209.196 16.2377C211.057 16.2377 214.11 17.1917 216.257 18.4796L219.071 12.708C216.638 10.9908 213.156 9.94141 209.816 9.94141C203.996 9.94141 200.18 13.185 200.18 17.8595C200.18 26.1591 212.202 24.4897 212.202 27.0177C212.202 27.6855 211.391 28.0671 210.15 28.0671C207.813 28.0671 204.235 27.0177 202.04 25.4437L199.178 30.9768C201.897 33.0755 206.047 34.3634 209.816 34.3634C215.493 34.3634 219.405 31.1676 219.358 26.5407C219.31 18.3365 207.288 19.7674 207.288 17.2394Z"
            fill="#003DFF"
          />
          <path
            id="Vector_2"
            d="M188.821 9.93273C186.436 9.93273 184.385 10.7436 183.049 12.1746V0.488281H175.988V33.8777H183.049V32.1129C184.194 33.4484 186.293 34.3547 188.774 34.3547C194.785 34.3547 199.317 29.1555 199.317 22.096C199.317 15.0842 194.785 9.93273 188.821 9.93273ZM187.486 27.6768C184.623 27.6768 182.667 25.3873 182.667 22.096C182.667 18.8525 184.623 16.6106 187.486 16.6106C190.3 16.6106 192.256 18.8525 192.256 22.096C192.256 25.3873 190.3 27.6768 187.486 27.6768Z"
            fill="#003DFF"
          />
          <path
            id="Vector_3"
            d="M162.536 9.94141C155.523 9.94141 150.705 14.9498 150.705 22.1524C150.705 29.355 155.523 34.3634 162.536 34.3634C169.549 34.3634 174.367 29.355 174.367 22.1524C174.367 14.9498 169.549 9.94141 162.536 9.94141ZM162.536 27.6855C159.722 27.6855 157.766 25.4437 157.766 22.1524C157.766 18.8612 159.722 16.6193 162.536 16.6193C165.351 16.6193 167.307 18.8612 167.307 22.1524C167.307 25.4437 165.351 27.6855 162.536 27.6855Z"
            fill="#003DFF"
          />
          <path
            id="Vector_4"
            d="M141.833 26.9136H137.205V33.8777H149.084V0.488281H141.833V26.9136Z"
            fill="#003DFF"
          />
          <path
            id="Vector_5"
            d="M127.951 9.94141C125.757 9.94141 123.61 10.7523 122.036 12.1833L122.226 10.4184H115.166L111.922 41.2321H118.982L119.889 32.1215C121.415 33.6002 123.467 34.3634 125.709 34.3634C131.052 34.3634 137.111 30.0705 137.111 21.2938C137.111 13.2804 132.436 9.94141 127.951 9.94141ZM124.755 27.6855C122.226 27.6855 120.461 25.6821 120.461 22.7725C120.461 19.1474 122.608 16.6193 125.757 16.6193C128.285 16.6193 130.05 18.6227 130.05 21.5323C130.05 25.1575 127.904 27.6855 124.755 27.6855Z"
            fill="black"
          />
          <path
            id="Vector_6"
            d="M105.813 10.4102L104.478 23.0504C104.239 25.34 102.999 27.6773 100.184 27.6773C97.7511 27.6773 97.1786 25.9601 97.1786 24.2429C97.1786 23.8136 97.2263 23.432 97.274 23.0504L98.6098 10.4102H91.5015L90.1657 23.0504C90.118 23.6228 90.0703 24.1952 90.0703 24.7199C90.0703 30.253 93.1235 34.3552 99.2777 34.3552C106.481 34.3552 110.87 29.8237 111.586 23.0504L112.922 10.4102H105.813Z"
            fill="black"
          />
        </g>
        <path
          id="Vector_7"
          d="M63.6895 33.8766H70.75V17.0864H77.2381V33.8766H88.7831V27.1987H84.2986V17.0864H88.7831V10.4085H84.2986V4.39844H77.2381V10.4085H63.6895V33.8766Z"
          fill="#003DFF"
        />
        <path
          id="Vector_8"
          d="M41.7435 10.3984V17.0763H54.338V18.7935H52.0481C43.9857 18.7935 40.8848 22.8479 40.8848 26.807C40.8848 31.8631 44.5582 34.3434 48.5178 34.3434C50.76 34.3434 53.0976 33.4849 54.338 31.8631V33.8665H61.3986V10.3984H41.7435ZM49.9013 27.8563C48.6609 27.8563 48.0884 27.1886 48.0884 26.2346C48.0884 24.8513 49.3288 23.9927 52.1435 23.9927H54.338C54.338 26.2823 52.0958 27.8563 49.9013 27.8563Z"
          fill="#003DFF"
        />
        <path
          id="Vector_9"
          d="M28.0999 33.8665H39.6448V27.1886H35.1604V17.0764H39.6448V10.3985H35.1604V4.29297H28.0999V10.3985H25.6191V17.0764H28.0999V33.8665Z"
          fill="#003DFF"
        />
        <path
          id="Vector_10"
          d="M14.0257 34.3473C20.2753 34.3473 26.0954 30.8653 26.0954 23.7104C26.0954 11.4517 9.15965 15.3154 9.15965 9.73455C9.15965 8.01737 10.7817 7.11109 12.9285 7.11109C15.5523 7.11109 19.0349 8.30357 21.2771 10.0684L25.4753 4.20144C22.3266 1.53028 17.222 0.00390625 13.2624 0.00390625C7.06056 0.00390625 1.52661 3.67675 1.52661 10.3069C1.52661 21.8501 18.4624 18.9882 18.4624 24.092C18.4624 25.6184 16.8881 27.1448 13.9303 27.1448C11.4018 27.1448 7.77616 26.0477 4.81836 22.9949L0 28.4326C3.33945 32.0101 8.87341 34.3473 14.0257 34.3473Z"
          fill="#003DFF"
        />
      </g>
    </g>
  </svg>
</template>
