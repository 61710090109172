<script setup lang="ts">
import { useAuth } from "../composables/useAuth";
import { type RouteLocationRaw } from "vue-router";

const props = defineProps<{
  forceRedirectTo?: RouteLocationRaw;
  signInForceRedirectTo?: RouteLocationRaw;
}>();

const { openSignUp } = useAuth({
  forceRedirectTo: props.forceRedirectTo,
  signInForceRedirectTo: props.signInForceRedirectTo,
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    en: {
      registration: "Sign up",
    },
    cs: {
      registration: "Registrovat",
    },
  },
});
</script>

<template>
  <button @click="openSignUp()">
    <slot>
      {{ t("registration") }}
    </slot>
  </button>
</template>
