<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_30939_196468)">
      <path
        d="M1 1H15"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M1 15H15"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M1 8H19"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_30939_196468">
        <rect width="20" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
