import { keepPreviousData } from "@tanstack/vue-query";

export const useNotificationsQuery = () => {
  return useSuspenseInfiniteQuery({
    queryKey: ["notifications"],
    queryFn: ({ pageParam }) =>
      $apiFetch<any>("/admin-api/notifications", {
        params: { page: pageParam },
      }),
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 20) {
        return pages.length + 1;
      }

      return undefined;
    },
  });
};
