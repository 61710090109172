export const useScrollDirection = () => {
  const { y } = useWindowScroll();
  const isScrollingUp = ref(false);
  const isScrollingDown = ref(false);

  watchImmediate(y, (newY: number, oldY: number) => {
    if (newY > oldY) {
      isScrollingDown.value = true;
      isScrollingUp.value = false;
    } else {
      isScrollingDown.value = false;
      isScrollingUp.value = true;
    }
  });

  return { isScrollingUp, isScrollingDown };
};
