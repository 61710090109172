<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_19914_316286)">
      <path
        d="M19 6H1V19H19V6Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.1328 1H6.13281V6H14.1328V1Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M13.4713 10L8.47125 15L6.53125 13.07"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_19914_316286">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
