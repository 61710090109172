<script setup lang="ts">
import { useAuth } from "../composables/useAuth";
import { type RouteLocationRaw } from "vue-router";

const props = defineProps<{
  forceRedirectTo?: RouteLocationRaw;
  signUpForceRedirectTo?: RouteLocationRaw;
}>();

const { openSignIn } = useAuth({
  forceRedirectTo: props.forceRedirectTo,
  signUpForceRedirectTo: props.signUpForceRedirectTo,
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    en: {
      login: "Log in",
    },
    cs: {
      login: "Přihlásit se",
    },
  },
});
</script>

<template>
  <button @click="openSignIn()">
    <slot>
      {{ t("login") }}
    </slot>
  </button>
</template>
