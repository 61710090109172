<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_19990_326124)">
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M1 10H19" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M10 1V19" stroke-width="1.5" stroke-miterlimit="10" />
      <path
        d="M10 19C12.7614 19 15 14.9706 15 10C15 5.02944 12.7614 1 10 1C7.23858 1 5 5.02944 5 10C5 14.9706 7.23858 19 10 19Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_19990_326124">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
