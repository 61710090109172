<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.25" width="40" height="40" fill="none" />
    <g clip-path="url(#clip0_39947_10451)">
      <path
        d="M13.916 6.66602H26.5826C26.5826 6.66602 26.6588 6.67554 26.6874 6.68506C27.0493 6.73268 27.4112 6.76125 27.7541 6.83744C29.0207 7.10411 30.135 7.66602 31.0874 8.53268C32.4588 9.76125 33.2683 11.2851 33.5255 13.1041C33.5445 13.2946 33.5636 13.4755 33.5826 13.666V26.3327C33.5826 26.3327 33.5826 26.4089 33.5636 26.4374C33.535 26.666 33.535 26.8946 33.4874 27.1136C33.2493 28.5232 32.6683 29.7898 31.7064 30.847C30.2683 32.447 28.4588 33.3136 26.316 33.3232C22.2874 33.3613 18.2588 33.3422 14.2398 33.3422C13.6493 33.3422 13.0588 33.2565 12.4874 33.1041C11.3255 32.7993 10.2874 32.2565 9.40168 31.447C7.80168 29.9898 6.94454 28.1803 6.93501 26.0184C6.90644 22.0089 6.92549 17.9803 6.93501 13.9708C6.93501 13.5613 6.97311 13.1517 7.0493 12.7422C7.36358 11.0184 8.19215 9.5803 9.52549 8.43744C10.6302 7.48506 11.916 6.91363 13.3731 6.73268C13.5541 6.70411 13.735 6.68506 13.916 6.66602ZM20.2493 27.3993C24.3445 27.3993 27.6493 24.0946 27.6588 19.9993C27.6588 15.9232 24.3636 12.6374 20.335 12.5898C16.2017 12.5422 12.8398 15.8946 12.8398 19.9898C12.8398 24.0851 16.1541 27.3993 20.2398 27.3993H20.2493ZM27.6588 11.1136C27.6588 11.9327 28.335 12.6089 29.1445 12.5898C29.9541 12.5898 30.6207 11.9136 30.6302 11.1041C30.6302 10.2946 29.9541 9.6184 29.1445 9.6184C28.3255 9.6184 27.6493 10.2946 27.6588 11.1041V11.1136Z"
        fill="currentColor"
      />
      <path
        d="M20.2486 15.5527C22.6963 15.5527 24.6963 17.5432 24.6963 20.0004C24.6963 22.4575 22.6963 24.448 20.2486 24.448C17.801 24.448 15.8105 22.448 15.8105 20.0004C15.8105 17.5432 17.801 15.5527 20.2486 15.5527Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_39947_10451">
        <rect
          width="26.6667"
          height="26.6667"
          fill="currentColor"
          transform="translate(6.91602 6.66602)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
