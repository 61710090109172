<template>
  <FooterDesktopWrapper
    class="hidden md:flex"
    @switch-locale="emit('switchLocale', $event)"
  >
    <slot name="desktop" />
  </FooterDesktopWrapper>
  <FooterMobileWrapper
    class="flex md:hidden"
    @switch-locale="emit('switchLocale', $event)"
  >
    <slot name="mobile" />
  </FooterMobileWrapper>
</template>
<script setup lang="ts">
import { default as FooterDesktopWrapper } from "@ui/components/footer/_FooterDesktopWrapper.vue";
import { default as FooterMobileWrapper } from "@ui/components/footer/_FooterMobileWrapper.vue";

const emit = defineEmits(["switchLocale"]);
</script>
