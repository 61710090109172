import {useMutation} from "@tanstack/vue-query";
import {computed} from "vue";
export const useSwitchUserLocaleQuery = () => {
    const { locale } = useI18n();
    const switchLocale = computed(() => {
        return locale.value === 'cs' ? 'en' : 'cs';
    });
    return useMutation({
        mutationFn: async () => {
            const formData = new FormData();
            formData.append("locale", switchLocale.value);

            return await $apiFetch(`/api/user/switch-locale`, {
                method: "POST",
                body: formData,
            });
        }
    });
};
