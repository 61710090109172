import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useNotificationsReadQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      $apiFetch(`/api/notifications/markAsDisplayed`, {
        method: "POST",
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'session' || query.queryKey[0] === 'notifications',
      }),
  });
};
