<script setup lang="ts">
import { useAuth } from "../composables/useAuth";
import { type RouteLocationRaw } from "vue-router";

const props = defineProps<{ forceRedirectTo?: RouteLocationRaw }>();

const { openTwoFA } = useAuth({
  forceRedirectTo: props.forceRedirectTo,
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    en: {
      verify: "Verify",
    },
    cs: {
      verify: "Ověřit",
    },
  },
});
</script>

<template>
  <button @click="openTwoFA()">
    <slot>
      {{ t("verify") }}
    </slot>
  </button>
</template>
