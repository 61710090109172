<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_19914_316237)">
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <circle cx="7" cy="8" r="2" fill="black" />
      <circle cx="13" cy="8" r="2" fill="black" />
      <path
        d="M6 14.5C6.91 13.29 8.36 12.5 10 12.5C11.64 12.5 13.09 13.29 14 14.5"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_19914_316237">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
