<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_36474_243374)">
      <path
        d="M18.9453 3C19.4976 3 19.9453 2.55228 19.9453 2C19.9453 1.44772 19.4976 1 18.9453 1C18.393 1 17.9453 1.44772 17.9453 2C17.9453 2.55228 18.393 3 18.9453 3Z"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M15.5 2H1.05469V16H5.05469V19L9.05469 16H19.0547V5.5"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M14.0547 12H6.05469"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M10.0547 9C11.1593 9 12.0547 8.10457 12.0547 7C12.0547 5.89543 11.1593 5 10.0547 5C8.95012 5 8.05469 5.89543 8.05469 7C8.05469 8.10457 8.95012 9 10.0547 9Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_36474_243374">
        <rect
          width="20.89"
          height="21"
          fill="white"
          transform="translate(0.0546875)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
