<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_19062_100848)">
      <path
        d="M3 9C3 9 3 11.66 3 12C3 14.5 1 16 1 16V18H9"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M9 21C7.34 21 6 19.66 6 18"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M15 9.0003C15 8.4603 15 11.2603 15 12.0003C15.02 14.5003 17 16.0003 17 16.0003V18.0003H9"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M9 21C10.66 21 12 19.66 12 18"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M3 9C3 5.69 5.69 3 9 3C12.31 3 15 5.69 15 9"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M10 2H8C8 1.45 8.45 1 9 1C9.55 1 10 1.45 10 2Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M10 1H8V2.8H10V1Z" stroke-width="1.5" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_19062_100848">
        <rect width="18" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
