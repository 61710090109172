<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { userSessionQuery } from "../queries/_userSessionQuery";

const { data: session } = useQuery(userSessionQuery());
</script>

<template>
  <slot v-if="session?.twoFactorVerified" :user="session.user" />
</template>
