<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14 4C14 2.895 13.105 2 12 2C10.895 2 10 2.895 10 4C10 5.105 10.895 6 12 6C13.105 6 14 5.105 14 4ZM14 12C14 10.895 13.105 10 12 10C10.895 10 10 10.895 10 12C10 13.105 10.895 14 12 14C13.105 14 14 13.105 14 12ZM14 20C14 18.895 13.105 18 12 18C10.895 18 10 18.895 10 20C10 21.105 10.895 22 12 22C13.105 22 14 21.105 14 20Z"
      fill="black"
    />
  </svg>
</template>
