<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="40" height="40" fill="none" />
    <g clip-path="url(#clip0_39947_10447)">
      <path
        d="M33.5 31.3805L22.8158 17.2923L32.3896 7H30.3018L21.8569 16.029L15.0106 7H7.5L17.7097 20.4646L7.5 31.3805H9.71104L18.6784 21.7402L25.9894 31.3805H33.5ZM14.2144 8.6022L30.2734 29.7783H26.7843L10.7254 8.6022H14.2144Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_39947_10447">
        <rect
          width="26"
          height="24.3805"
          fill="currentColor"
          transform="translate(7.5 7)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
