<template>
  <div class="w-full bg-black">
    <div
      class="container-native flex flex-col bg-black md:gap-16 md:px-8 md:pb-8 md:pt-16 lg:gap-20 lg:px-12 lg:pb-12 lg:pt-20"
    >
      <slot />
      <FooterBottom @switch-locale="emit('switchLocale', $event)" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { default as FooterBottom } from "@ui/components/footer/_FooterBottom.vue";

const emit = defineEmits(["switchLocale"]);
</script>
