<template>
  <Primitive
    v-bind="props"
    :class="[
      active
        ? 'border-blue-700 text-blue-700'
        : 'border-white text-black hover:text-blue-700',
      '-tracking-1 font-display inline-flex items-center justify-center border-b-2 px-3 text-sm font-semibold text-black transition-colors hover:text-blue-700 focus-visible:-outline-offset-4',
    ]"
  >
    <slot />
  </Primitive>
</template>
<script setup lang="ts">
import { Primitive, type PrimitiveProps } from "radix-vue";

const props = withDefaults(
  defineProps<PrimitiveProps & { active: boolean }>(),
  {
    as: "div",
    active: false,
  },
);
</script>
