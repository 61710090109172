<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_20145_309440)">
      <path
        d="M8 17H19V5H3V12"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.5 1H7.5V5H14.5V1Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M1 17H5"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M3 19V15"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_20145_309440">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
