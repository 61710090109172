import type { AuthRedirectOptions } from "./_useAuthRedirect";
import { useAuthRouting } from "./_useAuthRouting";
import { useQuery } from "@tanstack/vue-query";
import { userSessionQuery } from "../queries/_userSessionQuery";

export function useAuth(options?: AuthRedirectOptions) {
  const { data: session, isPending } = useQuery(userSessionQuery());
  const { openFragment, closeFragment } = useAuthRouting(options);

  const isLoaded = computed(() => {
    return session.value !== undefined;
  });

  const isSignedOut = computed(() => {
    return session.value === null;
  });

  const isSignedIn = computed(() => {
    return session.value && session.value.twoFactorVerified;
  });

  const isPartiallySignedIn = computed(() => {
    return session.value && !session.value.twoFactorVerified;
  });

  return {
    isSignedIn,
    isSignedOut,
    isPartiallySignedIn,
    isLoading: isPending,
    isLoaded,
    openSignIn: () => openFragment("login"),
    openSignUp: () => openFragment("register"),
    openSignOut: () => openFragment("logout"),
    openForgotPassword: () => openFragment("forgottenPassword"),
    openTwoFA: () => openFragment("twoFA"),
    closeSignIn: closeFragment,
    closeSignUp: closeFragment,
    closeSignOut: closeFragment,
    closeForgotPassword: closeFragment,
    closeTwoFA: closeFragment,
  };
}
