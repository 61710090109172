<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" fill="none" />
    <g clip-path="url(#clip0_39947_10456)">
      <path
        d="M34.5299 12.9245C34.3748 12.025 34.2002 11.5874 33.7363 10.958C33.2806 10.3395 32.5209 9.77928 31.5116 9.5798C29.5684 9.19518 25.8419 9 20 9C14.158 9 10.4316 9.19518 8.48885 9.57933C7.47956 9.7788 6.71985 10.339 6.26412 10.9575C5.80027 11.5869 5.62514 12.025 5.47052 12.9241C5.18563 14.5757 5 16.4296 5 19.4761C5 22.5226 5.18563 24.3765 5.47005 26.0276C5.62514 26.9272 5.7998 27.3648 6.26364 27.9942C6.71937 28.6127 7.47908 29.1729 8.48837 29.3724C10.4311 29.7565 14.1576 29.9517 19.9995 29.9517C25.8415 29.9517 29.568 29.7565 31.5107 29.3724C32.5199 29.1729 33.2797 28.6127 33.7354 27.9942C34.1992 27.3648 34.3744 26.9267 34.529 26.0276C34.8134 24.376 34.999 22.5226 34.999 19.4761C34.999 16.4296 34.8134 14.5757 34.529 12.9245H34.5299ZM17.0933 23.9494V15.0028L24.9801 19.4761L17.0933 23.9494Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_39947_10456">
        <rect
          width="30"
          height="20.9522"
          fill="currentColor"
          transform="translate(5 9)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
