<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.75" width="40" height="40" fill="none" />
    <g clip-path="url(#clip0_39947_10441)">
      <path
        d="M34.0249 21.8166C33.9964 20.8547 33.8249 19.8928 33.5392 18.969C32.9392 17.0356 31.663 15.769 29.663 15.3023C28.6249 15.0547 27.5678 14.988 26.5107 15.1309C24.7297 15.388 23.2726 16.2261 22.3107 17.788C22.2726 17.8261 22.244 17.8737 22.2059 17.9213C22.1583 17.1785 22.1583 16.4642 22.1678 15.7499C22.1678 15.569 22.1297 15.5023 21.9297 15.5023C20.3297 15.5118 18.7202 15.5118 17.1107 15.5023C16.8916 15.5023 16.8535 15.5785 16.8535 15.7785C16.8535 21.5404 16.8535 27.3118 16.8535 33.0737C16.8535 33.2832 16.9107 33.3404 17.1202 33.3404C18.7773 33.3404 20.4249 33.3404 22.0821 33.3404C22.3773 33.3404 22.3964 33.3404 22.3964 33.0451C22.3964 30.2832 22.3773 27.5309 22.3964 24.7785C22.3964 24.1499 22.4249 23.5213 22.5107 22.9023C22.5868 22.1785 22.8249 21.5023 23.2916 20.9213C24.1202 19.8928 25.9297 19.769 26.9202 20.188C27.7202 20.5213 28.1202 21.1785 28.3297 21.969C28.5107 22.6547 28.5583 23.3499 28.5583 24.0451V32.9975C28.5583 33.1023 28.5583 33.2071 28.5773 33.3404H33.8249C34.0091 33.3404 34.1011 33.2483 34.1011 33.0642C34.1011 30.5975 34.1011 28.1213 34.1011 25.6452C34.1011 24.3785 34.1011 23.1118 34.044 21.8356L34.0249 21.8166Z"
        fill="currentColor"
      />
      <path
        d="M13.1028 15.5059H8.12189C7.8457 15.5059 7.8457 15.5059 7.8457 15.7725C7.8457 21.544 7.8457 27.3059 7.8457 33.0678C7.8457 33.2963 7.91237 33.3249 8.11237 33.3249C9.77904 33.3249 11.4457 33.3249 13.1124 33.3249C13.3028 33.3249 13.3981 33.2297 13.3981 33.0392V15.8011C13.3981 15.6106 13.3028 15.5154 13.1124 15.5154L13.1028 15.5059Z"
        fill="currentColor"
      />
      <path
        d="M10.616 6.66602C8.83506 6.66602 7.41602 8.09459 7.41602 9.88506C7.41602 11.6755 8.83506 13.1041 10.6065 13.1041C12.3779 13.1041 13.8351 11.6755 13.8351 9.89459C13.8351 8.11363 12.397 6.67554 10.616 6.67554V6.66602Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_39947_10441">
        <rect
          width="26.6667"
          height="26.6667"
          fill="currentColor"
          transform="translate(7.41602 6.66602)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
