<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { Separator, type SeparatorProps } from "radix-vue";
import { cn } from "@ui/lib/utils";

const props = defineProps<
  SeparatorProps & { class?: HTMLAttributes["class"] }
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <Separator
    v-bind="delegatedProps"
    :class="
      cn(
        'bg-border shrink-0 bg-neutral-200',
        props.orientation === 'vertical' ? 'h-full w-px' : 'flex h-px',
        props.class,
      )
    "
  />
</template>
