<script setup lang="ts">
import { Primitive, type PrimitiveProps, useForwardProps } from "radix-vue";
import { computed, type HTMLAttributes } from "vue";
import { cn } from "@ui/lib/utils";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const props = withDefaults(
  defineProps<
    PrimitiveProps & {
      class?: HTMLAttributes["class"];
    }
  >(),
  {
    class: "",
  },
);

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;
  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <Primitive
    v-bind="forwardedProps"
    :class="
      cn(
        '-tracking-1 group flex w-full cursor-pointer flex-row px-3 py-3 font-sans text-base font-medium leading-6 text-black transition-colors disabled:cursor-not-allowed data-[disabled]:cursor-not-allowed data-[state=open]:bg-neutral-200',
        props.class,
      )
    "
  >
    <div
      v-if="$slots.icon"
      class="mr-3 flex size-11 flex-none items-center justify-center bg-neutral-200 group-data-[state=open]:bg-white"
    >
      <slot name="icon" />
    </div>

    <div class="flex w-full flex-col items-start justify-between gap-y-0.5">
      <div
        v-if="$slots.default"
        class="leading-tighter -tracking-2 font-sans text-xl font-semibold"
      >
        <slot />
      </div>

      <div
        v-if="$slots.perex"
        class="text-sm font-medium leading-none text-neutral-400"
      >
        <slot name="perex" />
      </div>
    </div>

    <div class="ml-4 flex items-center max-sm:hidden">
      <ChevronRightIcon
        class="size-4 stroke-[2px] group-data-[state=open]:text-blue-700"
      />
    </div>
  </Primitive>
</template>
