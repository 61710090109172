<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" fill="none" />
    <path
      d="M19.9993 6.66797C12.6352 6.66797 6.66602 12.6756 6.66602 20.0873C6.66602 26.7683 11.5154 32.3031 17.8641 33.3346V23.8552H14.5379V20.0873H17.8641V18.2152C17.8641 16.4626 17.774 15.1446 19.1547 13.4158C21.0527 11.0471 26.0017 12.1551 26.0017 12.1551V15.5935C22.6755 15.5505 22.2674 15.8275 22.2674 18.2105V20.0825H25.8119L25.2188 23.8504H22.2674V33.306C28.5498 32.2219 33.3327 26.7157 33.3327 20.0825C33.3327 12.6756 27.3635 6.66797 19.9993 6.66797Z"
      fill="currentColor"
    />
  </svg>
</template>
