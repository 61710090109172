<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_39588_536)">
      <path
        d="M5.66602 6H15.666"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M5.66602 10H11.666"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M19.666 1H1.66602V15H5.66602V18L9.66602 15H19.666V1Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_39588_536">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.666016)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts"></script>
