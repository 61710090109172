<template>
  <div class="flex flex-col gap-12 bg-black px-6 pb-8 pt-12">
    <slot />
    <FooterBottom @switch-locale="emit('switchLocale', $event)" />
  </div>
</template>
<script setup lang="ts">
import { default as FooterBottom } from "@ui/components/footer/_FooterBottom.vue";

const emit = defineEmits(["switchLocale"]);
</script>
